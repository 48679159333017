
import { computed, defineComponent, ref } from 'vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useI18n } from 'vue-i18n'
import UserCard from '@bd/components/User/UserCard/UserCard.vue'
import UserDetailsCard from '@bd/admin/components/Users/UserDetailsCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@bd/admin/store'
import { ClientDetailsDto } from '@bd/api'
import WantedFlatDetails from '@bd/admin/components/Clients/WantedFlatDetails.vue'
import { UserRole } from '@bd/api'
import { ClientEventMessages } from '@bd/admin/config/toast'
import { useToast } from 'primevue/usetoast'
import { WantedFlatDetailsType } from '@bd/admin/types'
import {
  ClientNoteSaveDialog,
  ClientNote,
  ConfirmDialog,
  ClientNoteMessages,
  Svg,
} from '@bd/components'
import { deleteNote } from '@bd/api/common/clients-management-api'

export default defineComponent({
  name: 'Client',
  components: {
    AppContentLayout,
    UserCard,
    UserDetailsCard,
    WantedFlatDetails,
    ConfirmDialog,
    ClientNoteSaveDialog,
    ClientNote,
    Svg,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const showClientNoteSaveDialog = ref(false)

    const clientDetails = computed<ClientDetailsDto | undefined>(
      () => store.state.clients?.clientDetails,
    )

    const agentDetails = computed(() => {
      const { firstName, lastName, email, phoneNumber, userId } =
        clientDetails.value?.agent ?? {}
      return clientDetails.value?.agent
        ? {
            firstName,
            lastName,
            email,
            phoneNumber,
            userId,
            userRole: [UserRole.AGENT],
          }
        : null
    })

    const isSeller = computed(() =>
      clientDetails.value?.roles.includes(UserRole.SELLER),
    )

    const wantedFlatDetails = computed<WantedFlatDetailsType | undefined>(
      () => {
        if (!clientDetails.value) {
          return undefined
        }
        return {
          city: clientDetails.value.customerCity,
          areaMax: clientDetails.value.customerAreaMax,
          areaMin: clientDetails.value.customerAreaMin,
          priceMax: clientDetails.value.customerPriceMax,
          priceMin: clientDetails.value.customerPriceMin,
          market: clientDetails.value.customerMarket,
        }
      },
    )

    const fetchClientDetails = async () => {
      await store.dispatch('clients/client', route.params.id)
    }

    const performConfirmationRequiredAction = async (
      action: () => Promise<void>,
      errorHandler: (err: unknown) => void,
    ) => {
      try {
        await action()
      } catch (err) {
        errorHandler(err)
      }
    }

    const blockClient = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('clients/blockClient', route.params.id)
          toast.add(ClientEventMessages.blockSuccess)
        },
        () => {
          toast.add(ClientEventMessages.blockError)
        },
      )
    }

    const unblockClient = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('clients/unblockClient', route.params.id)
          toast.add(ClientEventMessages.unblockSuccess)
        },
        () => {
          toast.add(ClientEventMessages.unblockError)
        },
      )
    }

    const user = computed<Partial<ClientDetailsDto | undefined>>(() => {
      if (!clientDetails.value) {
        return undefined
      }
      return {
        id: clientDetails.value.userId,
        roles: clientDetails.value.roles,
        userId: clientDetails.value.userId,
        firstName: clientDetails.value.firstName,
        lastName: clientDetails.value.lastName,
        email: clientDetails.value.email,
        phoneNumber: clientDetails.value.phoneNumber,
        avatarUrl: clientDetails.value.avatarUrl,
        displayableId: clientDetails.value.displayableId,
        sellerDeveloper: clientDetails.value.sellerDeveloper,
      }
    })

    const goToUpdate = () => {
      if (!clientDetails.value) {
        return
      }

      router.push({
        name: 'ClientUpdate',
        params: { id: clientDetails.value.userId },
      })
    }

    const goToOffer = () => {
      if (!clientDetails.value || !clientDetails.value.offerId) {
        return
      }

      router.push({
        name: 'OfferUpdate',
        params: { id: clientDetails.value.offerId },
      })
    }

    const showConfirmationDialog = ref(false)
    const noteToDelete = ref<number>()

    const onNoteDelete = async (noteId: number) => {
      noteToDelete.value = noteId
      showConfirmationDialog.value = true
    }

    const onDialogConfirm = async () => {
      try {
        await deleteNote(noteToDelete.value!)
        toast.add(ClientNoteMessages.deleteSuccess)
      } catch (error) {
        toast.add(ClientNoteMessages.deleteError)
        return
      }
      await fetchClientDetails()
      showConfirmationDialog.value = false
    }

    const onDialogReject = () => {
      showConfirmationDialog.value = false
    }

    const onNoteAdded = () => {
      showClientNoteSaveDialog.value = false
      fetchClientDetails()
    }

    return {
      t,
      clientDetails,
      UserRole,
      blockClient,
      unblockClient,
      user,
      agentDetails,
      isSeller,
      wantedFlatDetails,
      goToUpdate,
      showClientNoteSaveDialog,
      onNoteAdded,
      goToOffer,
      showConfirmationDialog,
      onNoteDelete,
      onDialogConfirm,
      onDialogReject,
    }
  },
})
