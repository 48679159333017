
import { computed, defineComponent, PropType } from 'vue'
import { LOCALISATION_SETTINGS, Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { WantedFlatDetailsType } from '@bd/admin/types'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'WantedFlatDetails',
  components: { Svg },
  props: {
    customerCity: {
      type: String,
      default: '',
    },
    wantedFlatDetails: {
      type: Object as PropType<WantedFlatDetailsType>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS

    const prices = computed(() => ({
      min: getFormattedPrice(
        props.wantedFlatDetails.priceMin,
        currency,
        locale,
      ),
      max: getFormattedPrice(
        props.wantedFlatDetails.priceMax,
        currency,
        locale,
      ),
    }))

    return {
      t,
      currency,
      prices,
    }
  },
})
