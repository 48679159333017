<template>
  <div class="wanted-flat-details">
    <div class="row h-100">
      <div class="col-md-3 wanted-flat-details-col">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span class="body-12">{{ t('clients.form.wanted_apartment') }}</span>
          <div class="rounded-icon-container">
            <Svg :src="require('@bd/assets/icons/location.svg')" />
          </div>
        </div>
        <span class="detail-value" v-if="wantedFlatDetails.city?.length">{{
          wantedFlatDetails.city
        }}</span>
      </div>
      <div class="col-md-3 wanted-flat-details-col">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span class="body-12">{{ t('clients.form.area') }}</span>
          <div class="rounded-icon-container">
            <Svg :src="require('@bd/assets/icons/square.svg')" />
          </div>
        </div>
        <span
          class="detail-value"
          v-if="wantedFlatDetails.areaMin || wantedFlatDetails.areaMax"
        >
          {{ wantedFlatDetails.areaMin }} -
          {{ wantedFlatDetails.areaMax }} m<sup>2</sup>
        </span>
      </div>
      <div class="col-md-3 wanted-flat-details-col">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <span class="body-12">{{ t('clients.form.price') }}</span>
          <div class="rounded-icon-container">
            <Svg :src="require('@bd/assets/icons/price.svg')" />
          </div>
        </div>
        <span
          class="detail-value"
          v-if="wantedFlatDetails.priceMin || wantedFlatDetails.priceMax"
        >
          {{ prices.min }} -
          {{ prices.max }}
        </span>
      </div>
      <div class="col-md-3 h-100 d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-between align-items-center">
          <span class="body-12">{{ t('clients.form.market') }}</span>
          <div class="rounded-icon-container">
            <Svg :src="require('@bd/assets/icons/market.svg')" />
          </div>
        </div>
        <span class="detail-value" v-if="wantedFlatDetails.market">
          {{ t(`enums.propertyMarket.${wantedFlatDetails.market}`) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { LOCALISATION_SETTINGS, Svg } from '@bd/components'
import { useI18n } from 'vue-i18n'
import { WantedFlatDetailsType } from '@bd/admin/types'
import { getFormattedPrice } from '@bd/helpers'

export default defineComponent({
  name: 'WantedFlatDetails',
  components: { Svg },
  props: {
    customerCity: {
      type: String,
      default: '',
    },
    wantedFlatDetails: {
      type: Object as PropType<WantedFlatDetailsType>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n()
    const { currency, locale } = LOCALISATION_SETTINGS

    const prices = computed(() => ({
      min: getFormattedPrice(
        props.wantedFlatDetails.priceMin,
        currency,
        locale,
      ),
      max: getFormattedPrice(
        props.wantedFlatDetails.priceMax,
        currency,
        locale,
      ),
    }))

    return {
      t,
      currency,
      prices,
    }
  },
})
</script>

<style lang="scss" scoped>
.wanted-flat-details {
  height: 160px;
  padding: 25px;
  border-radius: 6px;
  box-shadow: $default-shadow;
  background-color: $port-gore;
  color: $white;
}

.wanted-flat-details-col {
  height: 100%;
  @include flex($justify: space-between, $direction: column, $align: left);
  border-right: 1px solid rgba($athens-gray, 0.1);
}

.rounded-icon-container {
  @include uniform-size(40px);
  background-color: $white-transparent;
  border-radius: 40%;
  @include flex;
  i {
    @include uniform-size(16px);
  }
}
.detail-value {
  font-size: 1.25rem;
}
</style>
