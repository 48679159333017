<template>
  <div class="container-fluid client">
    <div class="row">
      <div class="col-12">
        <AppContentLayout backAction divider>
          <template #back-action>
            <Button
              @click="$router.back()"
              icon="pi pi-chevron-left"
              class="p-button-secondary p-button-circle"
            />
          </template>
          <template #title>
            <span>{{ t('clients.details') }}</span>
          </template>
          <template #actions>
            <Button
              v-if="isSeller && clientDetails?.offerId"
              class="p-button-sm p-button-secondary mr-3"
              :label="t('clients.go_to_offer')"
              @click="goToOffer"
            />
            <Button
              v-if="!clientDetails?.blocked"
              class="p-button-sm p-button-secondary mr-3"
              :label="t('block')"
              @click="blockClient"
            />
            <Button
              v-if="clientDetails?.blocked"
              class="p-button-sm p-button-secondary mr-3"
              :label="t('unblock')"
              @click="unblockClient"
            />
            <Button
              class="p-button-sm p-button-primary"
              :label="t('edit')"
              @click="goToUpdate"
            />
          </template>
          <div class="client-details-row">
            <div class="client-details-column">
              <h4 class="h-4 mb-3">{{ t('clients.information') }}</h4>
              <UserDetailsCard
                class="details-card"
                v-if="user"
                :user="user"
                :displayOptions="['id', 'description']"
                :description="clientDetails?.description"
              />
            </div>
            <div class="client-details-column" v-if="agentDetails">
              <h4 class="h-4 mb-3">{{ t('clients.employee') }}</h4>
              <UserCard
                :user="agentDetails"
                :displayOptions="['details', 'chip']"
              />
            </div>
          </div>
          <div
            v-if="clientDetails?.roles.includes(UserRole.CUSTOMER)"
            class="row mb-5"
          >
            <div class="col-md-12">
              <div class="row mb-4">
                <div class="col-md-12">
                  <span class="h-4 mb-3">{{
                    t('clients.form.wanted_apartment')
                  }}</span>
                </div>
              </div>
              <div class="row mb-4">
                <div class="col-md-12">
                  <WantedFlatDetails :wantedFlatDetails="wantedFlatDetails" />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row mb-3">
                <div class="col-md-12 h-100 d-flex align-items-center">
                  <div>
                    <span class="h-4 mb-3">{{ t('clients.notes') }}</span>
                  </div>
                  <Button
                    class="p-button-sm p-button-text ml-2"
                    @click="showClientNoteSaveDialog = true"
                  >
                    <Svg
                      :src="require('@bd/assets/icons/plus.svg')"
                      class="mr-2 plus-icon"
                    />
                    <span>{{ t('clients.add_note') }}</span>
                  </Button>
                </div>
              </div>
              <div class="client-notes-list" v-if="clientDetails.notes?.length">
                <ClientNote
                  v-for="note in clientDetails.notes"
                  :key="note.noteId"
                  :note="note"
                  @delete="onNoteDelete"
                />
              </div>
              <div class="empty-state" v-else>
                <h2 class="h-2 text-center">{{ t('clients.notes_empty') }}</h2>
              </div>
            </div>
          </div>
        </AppContentLayout>
      </div>
    </div>
    <ClientNoteSaveDialog
      v-if="clientDetails"
      v-model:visible="showClientNoteSaveDialog"
      :clientId="clientDetails.userId"
      @noteAdd="onNoteAdded"
    />
    <ConfirmDialog
      v-model:visible="showConfirmationDialog"
      title=""
      :description="t('clients.note_remove_confirmation')"
      @confirm="onDialogConfirm"
      @reject="onDialogReject"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import AppContentLayout from '@bd/admin/components/AppContentLayout.vue'
import { useI18n } from 'vue-i18n'
import UserCard from '@bd/components/User/UserCard/UserCard.vue'
import UserDetailsCard from '@bd/admin/components/Users/UserDetailsCard.vue'
import { useRoute, useRouter } from 'vue-router'
import { useAppStore } from '@bd/admin/store'
import { ClientDetailsDto } from '@bd/api'
import WantedFlatDetails from '@bd/admin/components/Clients/WantedFlatDetails.vue'
import { UserRole } from '@bd/api'
import { ClientEventMessages } from '@bd/admin/config/toast'
import { useToast } from 'primevue/usetoast'
import { WantedFlatDetailsType } from '@bd/admin/types'
import {
  ClientNoteSaveDialog,
  ClientNote,
  ConfirmDialog,
  ClientNoteMessages,
  Svg,
} from '@bd/components'
import { deleteNote } from '@bd/api/common/clients-management-api'

export default defineComponent({
  name: 'Client',
  components: {
    AppContentLayout,
    UserCard,
    UserDetailsCard,
    WantedFlatDetails,
    ConfirmDialog,
    ClientNoteSaveDialog,
    ClientNote,
    Svg,
  },
  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const route = useRoute()
    const router = useRouter()
    const toast = useToast()

    const showClientNoteSaveDialog = ref(false)

    const clientDetails = computed<ClientDetailsDto | undefined>(
      () => store.state.clients?.clientDetails,
    )

    const agentDetails = computed(() => {
      const { firstName, lastName, email, phoneNumber, userId } =
        clientDetails.value?.agent ?? {}
      return clientDetails.value?.agent
        ? {
            firstName,
            lastName,
            email,
            phoneNumber,
            userId,
            userRole: [UserRole.AGENT],
          }
        : null
    })

    const isSeller = computed(() =>
      clientDetails.value?.roles.includes(UserRole.SELLER),
    )

    const wantedFlatDetails = computed<WantedFlatDetailsType | undefined>(
      () => {
        if (!clientDetails.value) {
          return undefined
        }
        return {
          city: clientDetails.value.customerCity,
          areaMax: clientDetails.value.customerAreaMax,
          areaMin: clientDetails.value.customerAreaMin,
          priceMax: clientDetails.value.customerPriceMax,
          priceMin: clientDetails.value.customerPriceMin,
          market: clientDetails.value.customerMarket,
        }
      },
    )

    const fetchClientDetails = async () => {
      await store.dispatch('clients/client', route.params.id)
    }

    const performConfirmationRequiredAction = async (
      action: () => Promise<void>,
      errorHandler: (err: unknown) => void,
    ) => {
      try {
        await action()
      } catch (err) {
        errorHandler(err)
      }
    }

    const blockClient = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('clients/blockClient', route.params.id)
          toast.add(ClientEventMessages.blockSuccess)
        },
        () => {
          toast.add(ClientEventMessages.blockError)
        },
      )
    }

    const unblockClient = async () => {
      await performConfirmationRequiredAction(
        async () => {
          await store.dispatch('clients/unblockClient', route.params.id)
          toast.add(ClientEventMessages.unblockSuccess)
        },
        () => {
          toast.add(ClientEventMessages.unblockError)
        },
      )
    }

    const user = computed<Partial<ClientDetailsDto | undefined>>(() => {
      if (!clientDetails.value) {
        return undefined
      }
      return {
        id: clientDetails.value.userId,
        roles: clientDetails.value.roles,
        userId: clientDetails.value.userId,
        firstName: clientDetails.value.firstName,
        lastName: clientDetails.value.lastName,
        email: clientDetails.value.email,
        phoneNumber: clientDetails.value.phoneNumber,
        avatarUrl: clientDetails.value.avatarUrl,
        displayableId: clientDetails.value.displayableId,
        sellerDeveloper: clientDetails.value.sellerDeveloper,
      }
    })

    const goToUpdate = () => {
      if (!clientDetails.value) {
        return
      }

      router.push({
        name: 'ClientUpdate',
        params: { id: clientDetails.value.userId },
      })
    }

    const goToOffer = () => {
      if (!clientDetails.value || !clientDetails.value.offerId) {
        return
      }

      router.push({
        name: 'OfferUpdate',
        params: { id: clientDetails.value.offerId },
      })
    }

    const showConfirmationDialog = ref(false)
    const noteToDelete = ref<number>()

    const onNoteDelete = async (noteId: number) => {
      noteToDelete.value = noteId
      showConfirmationDialog.value = true
    }

    const onDialogConfirm = async () => {
      try {
        await deleteNote(noteToDelete.value!)
        toast.add(ClientNoteMessages.deleteSuccess)
      } catch (error) {
        toast.add(ClientNoteMessages.deleteError)
        return
      }
      await fetchClientDetails()
      showConfirmationDialog.value = false
    }

    const onDialogReject = () => {
      showConfirmationDialog.value = false
    }

    const onNoteAdded = () => {
      showClientNoteSaveDialog.value = false
      fetchClientDetails()
    }

    return {
      t,
      clientDetails,
      UserRole,
      blockClient,
      unblockClient,
      user,
      agentDetails,
      isSeller,
      wantedFlatDetails,
      goToUpdate,
      showClientNoteSaveDialog,
      onNoteAdded,
      goToOffer,
      showConfirmationDialog,
      onNoteDelete,
      onDialogConfirm,
      onDialogReject,
    }
  },
})
</script>

<style lang="scss" scoped>
.client {
  height: 100vh;
  overflow-y: auto;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 14px;
}

.client-details-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.client-details-column {
  &:first-of-type {
    margin-right: auto;
    margin-bottom: 1rem;
  }

  @include breakpoint-down(xl) {
    flex: 1 1 100%;
    .details-card {
      max-width: unset;
    }
  }
}
.client-notes-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.empty-state {
  width: 100%;
  margin: 1.5rem 0;
}
.plus-icon {
  @include uniform-size(20px);
  color: $primary-color;
}
</style>
